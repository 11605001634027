import Error from 'next/error';
import { Layout } from '../components/layout/layout';

const NotFoundPage = () => {
  return (
    <Layout>
      <Error statusCode={404} />
    </Layout>
  );
};
export default NotFoundPage;
